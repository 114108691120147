@font-face {
    font-family: "Inconsolata_Bold";
    src: url("/public/fonts/Inconsolata/static/Inconsolata/Inconsolata-Bold.ttf");
}

@font-face {
    font-family: "Inconsolata_Light";
    src: url("/public/fonts/Inconsolata/static/Inconsolata/Inconsolata-Light.ttf");
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.glass-menu {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.42);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.8px);
    -webkit-backdrop-filter: blur(5.8px);
    border: 1px solid rgba(255, 255, 255, 0.21);
}

#home-menu {
    width: 30em;
    height: 30em;
}

#login-menu {
    width: 30em;
    height: 20em;
}

.background-image {
    min-height: 100vh;
    min-width: 100vw;
}

.glass-button {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    height: 2em;
    width: 15em;
    border: 1px solid rgba(255, 255, 255, 0);
    font-family: 'Inconsolata_Bold', Arial, Helvetica, sans-serif;
}

#winBox {
    background: rgba(255, 255, 255, 0.7);
}

.chat-closed {
    height: 2em;
    width: 5em;
}

.chat-open {
    height: 25em;
    width: 20em;
}

.selected-chat {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    height: 2em;
    width: 4em;
    border: 1px solid rgba(255, 255, 255, 0);
    font-family: 'Inconsolata_Bold', Arial, Helvetica, sans-serif;
}

.unselected-chat {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    height: 2em;
    width: 4em;
    border: 1px solid rgba(255, 255, 255, 0);
    font-family: 'Inconsolata_Bold', Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.unselected-chat:hover {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    height: 2em;
    width: 4em;
    border: 1px solid rgba(255, 255, 255, 0);
    font-family: 'Inconsolata_Bold', Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.chat-bubble {
    border-radius: 8px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0);
    font-family: 'Inconsolata_Bold', Arial, Helvetica, sans-serif;
    color: white;
}

.chat-bubble-own {
    background: rgba(210, 4, 45, 0.57);
}

.chat-bubble-opponent {
    background: rgba(84, 179, 236, 0.55);
}

.fw-light {
    font-family: 'Inconsolata_Light', sans-serif;
}

.gi-LinkButton {
    color: black;
    text-decoration: none;
}

.gi-Button:hover {
    transform: scale(1.05) !important;
    background: rgba(255, 255, 255, 0.9);
}

.gi-linkButton-home:hover {
    transform: translateX(-50%) scale(1.05) !important;
}

#index-logo {
    max-width: 90%;
}

.game-background {
    width: 1000px;
    height: 872px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.game-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 960px;
    height: 832px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centerAbsolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
